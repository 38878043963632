<template>
    <OModal ref="configUrlModal" @show="onModalShown" @hide="clearValues">
        <div class="modal-dialog" style="max-width: 90%;">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t("Element Url Builder") }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div id="elementUrlBuilderModalBody" class="modal-body" style="height:65vh;">
                    <ORowContainer>
                        <OTabs id="elementsTabs" ref="elementsTabsRef">
                            <OTab :title="$t('Elements')" id="elements" v-if="showElements">
                                <OColContainer>
                                    <ODataGrid :dataObject="dsReportElementsUrlBuilder" hideGridMenu loadDataObject>
                                        <OColumn field="ArticleID" :headerName="$t('ArticleID')" width="300"/>
                                        <OColumn field="Article" :headerName="$t('Article')" width="300"/>
                                        <OColumn field="Tech" :headerName="$t('Tech')" hide width="50"/>
                                    </ODataGrid>
                                    <div class="ps-2" style="height:100%; width:50%; min-width:50%; overflow-y:auto">
                                        <div class="d-flex flex-row p-0">
                                            <span class="text-break"> {{ statusElementUrl }}</span>
                                            <a class="ms-auto" href="/o365setup?articleId=o365setup-statusreportingelements" target="_blank" rel="noreferrer noopener">
                                                <span>{{ $t("Elements Configuration") }}</span>
                                            </a>
                                        </div>
                                        <div v-for="item in elementsFilterInputs" :key="item.ID" class="d-flex flex-column">
                                            <div>
                                                <label :for="item.ID+'input'">{{ item.FieldName + (item.Description ? " ("+item.Description+")" : "") }}</label>
                                                <input :id="item.ID+'input'" type="text" class="form-control form-control-sm" v-model="item.Value" v-if="item.Type == 'text' || item.Type == 'string' || item.Type == 'number' || item.Type == null"/>
                                                <ODatePicker :id="item.ID+'input'" class="form-control form-control-sm w-100" v-model="item.Value" v-if="item.Type == 'date'" format="Short Date"></ODatePicker>
                                                <input :id="item.ID+'input'" type="checkbox" class="w-100 me-auto" v-model="item.Value" v-if="item.Type == 'checkbox'"/>
                                                <!-- lookups -->
                                                <OOrgUnitsLookup :id="item.ID+'input'" :value="item.Value" :bind="sel=>{ item.Value = sel.ID}" v-if="item.Type == 'orgUnitLookup' || item.InputEditorConfig?.Type == 'OrgUnit'" class="form-select form-select-sm" textInput readonly/>
                                                <OPersonsLookup :id="item.ID+'input'" :value="item.Value" :bind="sel=>{ item.Value = sel.ID}" v-if="item.Type == 'personsLookup'" class="form-select form-select-sm" readonly/>
                                                <OOrgUnitsLookup :id="item.ID+'input'" :value="item.Value" :bind="sel=>{ item.Value = sel.ID}" v-if="item.Type == 'companyLookup'" class="form-select form-select-sm" textInput readonly whereClause="Company_ID IS NOT NULL"/>
                                                <OScopeProcessesLookup :id="item.ID+'input'" :value="item.Value" :bind="sel=>{ item.Value = sel.ID}" v-if="item.Type == 'processLookup'" class="form-select form-select-sm" textInput readonly/>
                                                <OWBSLookup :value="item.Value" :bind="sel=>{ item.Value = sel.ID; }" v-if="item.Type == 'wbsLookup'" class="form-select form-select-sm" textInput readonly/>
                                                
                                                <DynamicLookup :id="item.ID+'input'" 
                                                    :config="item.AdditionalData" 
                                                    v-if="item.Type == 'inputEditor' && item.InputEditorConfig?.Type == 'Lookup'" 
                                                    @callbackFunction="sel=>{ item.Value = sel.ID}"
                                                />
                                                <DynamicElementLookupCT :id="item.ID+'input'" 
                                                    :appID="dsReportElementsUrlBuilder.current.ArticleID" 
                                                    :config="item"
                                                    v-if="item.Type == 'lookup'"
                                                    @callbackFunction="lookupCallback"
                                                />
                                            </div>
                                        </div>
                                        <iframe :src="statusElementUrl" class="w-100 h-100"></iframe>
                                    </div>
                                </OColContainer>
                            </OTab>
                            <OTab :title="$t('Charts')" id="charts" v-if="showCharts">
                                <OColContainer>
                                    <ODataGrid :dataObject="dsChartsUrlBuilder" hideGridMenu loadDataObject>
                                        <OColumn field="ChartLayout" :headerName="$t('Chart Layout')" width="300"/>
                                        <OColumn field="ChartLayoutTitle" :headerName="$t('Chart Layout Title')" width="300"/>
                                    </ODataGrid>
                                    <iframe id="chart-iframe" :src="'/chart-preview?Chart='+dsChartsUrlBuilder.current.ChartLayout" style="height:100%; width:50%; min-width:50%;"></iframe>
                                </OColContainer>
                            </OTab>
                            <OTab :title="$t('Reports')" id="reports" v-if="showReports">
                                <OColContainer>
                                    <ODataGrid :dataObject="dsMyReportsUrlBuilder" hideGridMenu loadDataObject>
                                        <OColumn field="ReportID" :headerName="$t('Report ID')" width="300"/>
                                        <OColumn field="Title" :headerName="$t('Title')" width="300"/>
                                    </ODataGrid>
                                    <div class="ps-2 d-flex flex-column" style="width:50%; min-width:50%; height:100%; overflow-y:auto; overflow-x:hidden;">
                                        <div class="d-flex flex-column">
                                            <div class="d-flex flex-row w-100">
                                                <div>
                                                    <label for="customFilterSelect">Select a filter for the report</label> 
                                                    <select class="form-select form-select-sm" name="Default" id="customFilterSelect" v-model="reportSelectedFilter">
                                                        <option value='Default' selected>Default</option>
                                                        <option v-for="filter in dsMyReportsFilterTemplates.data" :key="filter.Name" :value="filter">{{ filter.Name }}</option>
                                                    </select>
                                                </div>
                                                <div>
                                                    <button type="button" class="btn btn-sm btn-outline-primary ms-2 mt-4" @click="loadReport = true">Preview Report</button>
                                                </div>
                                                <a class="ms-auto" href='/myreports' target='_blank' rel='noreferrer noopener'>
                                                    <span>Reports Configuration <i class="far fa-external-link"></i></span>
                                                </a>
                                            </div>
                                            <div class="" style="line-height:3;">
                                                <div v-for="item in reportsFilterInputs">
                                                    <label :for="item.ID">{{ item.Text }}</label>
                                                    <ODatePicker :id="item.ID" class="form-control form-control-sm w-100" v-model="item.Value" v-if="item.Type == 'DatePicker'" format="Short Date"></ODatePicker>
                                                    <input :id="item.ID" type="checkbox" class="w-100" v-model="item.Value" v-else-if="item.Type == 'Radio'"/>
                                                    <OOrgUnitsLookup :id="item.ID" :value="item.Value" :bind="sel=>{ item.Value = sel.ID}" v-else-if="item.Type == 'OrgUnit_Lookup'" class="form-select form-select-sm" textInput readonly/>

                                                    <DynamicLookup :id="item.ID"
                                                                    :config="item"
                                                                    v-else-if="isCustomReportLookup(item.Type)"
                                                                    @callbackFunction="(row) => reportLookupCallback(row, item)"/>
                                                    
                                                    <input type="hidden"  v-model="item.Value" v-else-if="item.Type == null" />
                                                    <input :id="item.ID" type="text" class="form-control form-control-sm" v-model="item.Value" v-else/>

                                                </div>
                                            </div>
                                        </div>
                                        <span class="text-break"> {{ statusElementUrl }}</span>
                                        <div v-if="loadReport" class="">
                                            <div v-show="isReportLoading">
                                                {{ $t("Loading preview") }}
                                                <div class="spinner-border spinner-border-sm" role="status">
                                                    <span class="visually-hidden"></span>
                                                </div>
                                            </div>
                                            <img v-show="!isReportLoading" 
                                                :src="statusElementUrl" 
                                                class="border" 
                                                style="min-width:200px;min-height:200px;overflow-y:auto;" 
                                                :onload="onReportLoaded()"
                                            />
                                        </div>
                                    </div>
                                </OColContainer>
                            </OTab>
                            <OTab :title="$t('Interactive Charts')" id="interactiveCharts" v-if="showInteractiveCharts">
                                <OColContainer>
                                    <ODataGrid :dataObject="dsInteractiveCharts" hideGridMenu loadDataObject>
                                        <OColumn field="Name" :headerName="$t('Name')" width="300"/>
                                        <OColumn field="StoredProcedure" :headerName="$t('StoredProcedure')" width="300"/>
                                    </ODataGrid>
                                    <div class="d-flex flex-column h-100 overflow-auto" style="width:50%; min-width:50%;">
                                        <div class="d-flex flex-column ps-1">
                                            <template v-for="param in dsInteractiveChartsParameters.data" class="d-flex flex-col">
                                                <lable :for="'intChart'+param.ParamName" class="form-label">{{ param.ParamName }}</lable>
                                                <input :id="'intChart'+param.ParamName" type="text" class="form-control" @change="" v-model="interactiveChartParams[param.ParamName]"/>
                                            </template>
                                        </div>
                                        <div class="d-flex flex-row flex-grow flex-fill h-100" style="min-height: 400px">
                                            <ChartRender :key="interactiveChartKey" :chartConfig="dsInteractiveCharts.current.Layout" :procedure="dsInteractiveCharts.current.StoredProcedure" :procedureParams="interactiveChartParams"/>
                                        </div>
                                    </div>
                                </OColContainer>
                            </OTab>
                        </OTabs>
                    </ORowContainer>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" @click="setUrl">{{ $t("Set") }}</button>
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t("Cancel") }}</button>
                </div>
            </div>
        </div>
    </OModal>
</template>

<script setup lang="ts">
    import { ref, onMounted, computed, watch } from "vue";
    import { getOrCreateDataObject } from "o365-dataobject";
    import { OModal, ODatePicker, OIframe } from "o365-ui-components";
    import { useDataObjectEventListener } from "o365-vue-utils";
    import { utils, $t } from 'o365-utils';
    import { OOrgUnitsLookup, OPersonsLookup, OWBSLookup, OScopeProcessesLookup } from 'o365-system-lookups';
    
    import ChartRender from "status.vue.components.HighchartLayoutRender.vue";
    import DynamicElementLookupCT from "status.vue.components.DynamicElementLookupCT.vue";
    import DynamicLookup from "status.vue.components.DynamicLookup.vue";

    const props = defineProps({
        dataObject: {
            type: Object,
            required: true
        },
        showElements: {
            type: Boolean,
            default: false
        },
        showCharts: {
            type: Boolean,
            default: false
        },
        showReports: {
            type: Boolean,
            default: false
        },
        showInteractiveCharts: {
            type: Boolean,
            default: false
        }
    });

    const elementsTabsRef = ref(null);
    const configUrlModal = ref(null);

    const elementsFilterInputs:Array<any> = ref([]);
    const reportsFilterInputs:Array<any> = ref([]);

    const loadReport = ref(false);
    const isReportLoading = ref(true);
    const reportSelectedFilter = ref("Default");

    const statusElementUrl = ref("");
    const interactiveChartParams = ref({});
    const interactiveChartKey = ref(crypto.randomUUID());

    // Elements
    const dsReportElementsUrlBuilder = getOrCreateDataObject({
        id: "dsReportElementsUrlBuilder",
        viewName: "aviw_Status_Elements",
        maxRecords: -1,
        whereClause: "",
        selectFirstRowOnLoad: true,
        loadRecents: false,
        distinctRows: true,
        fields: [
            { name: "PrimKey", type: "string" },
            { name: "ArticleID", type: "string", sortOrder: 1, sortDirection: "asc" },
            { name: "Article", type: "string" },
            { name: "Tech", type: "string" },
            { name: "QueryString", type: "string" },
            { name: "Config", type: "string" },
            { name: "ElementConfigJSON", type: "string" }
        ]
    });
    // Charts
    const dsChartsUrlBuilder = getOrCreateDataObject({
        id: "dsChartsUrlBuilder",
        viewName: "aviw_Status_Charts",
        maxRecords: -1,
        whereClause: "",
        selectFirstRowOnLoad: true,
        loadRecents: false,
        distinctRows: true,
        fields: [
            { name: "ChartLayout", type: "string", sortOrder: 1, sortDirection: "asc" },
            { name: "ChartLayoutTitle", type: "string" },
        ]
    });
    // Reports
    const dsMyReportsUrlBuilder = getOrCreateDataObject({
        id: "dsMyReportsUrlBuilder",
        viewName: "sviw_Reporting_MyReports",
        maxRecords: -1,
        whereClause: "",
        selectFirstRowOnLoad: true,
        loadRecents: false,
        distinctRows: true,
        fields: [
            { name: "PrimKey", type: "string" },
            { name: "ReportID", type: "string", sortOrder: 1, sortDirection: "asc" },
            { name: "Title", type: "string" },
            { name: "ViewName", type: "string" },
            { name: "InitialFilter", type: "string" },
            { name: "FilterTemplate", type: "string" }
        ]
    });

    const dsMyReportsFilterTemplates = getOrCreateDataObject({
        id: "dsMyReportsFilterTemplates",
        viewName: "stbv_Database_FilterTemplates",
        maxRecords: 25,
        whereClause: "",
        selectFirstRowOnLoad: true,
        loadRecents: false,
        distinctRows: true,
        fields: [
            { name: "Name", type: "string" },
            { name: "ViewName", type: "string" },
            { name: "FilterTemplate", type: "string" }
        ],
        masterDataObject_ID: "dsMyReportsUrlBuilder",
        masterDetailDefinition: [
            {
                detailField: "ViewName",
                masterField: "ViewName",
                operator: "equals"
            }
        ],
    });

    const dsSystemInputEditors = getOrCreateDataObject({
        id: "dsSystemInputEditors",
        viewName: "stbv_Database_InputEditors",
        maxRecords: -1,
        whereClause: "",
        selectFirstRowOnLoad: true,
        loadRecents: false,
        distinctRows: true,
        fields: [
            { name: "ID", type: "number" },
            { name: "Name", type: "string" },
            { name: "Config", type: "string" }
        ]
    });

    //Highcharts
    const dsInteractiveCharts = getOrCreateDataObject({
        id: "dsInteractiveCharts",
        viewName: "stbv_O365_ChartLayouts",
        maxRecords: -1,
        whereClause: "",
        selectFirstRowOnLoad: true,
        loadRecents: false,
        distinctRows: false,
        fields: [
            { name: "ID", type: "number" },
            { name: "Name", type: "string" },
            { name: "StoredProcedure", type: "string" },
            { name: "Layout", type: "string" },
        ]
    });
    const dsInteractiveChartsParameters = getOrCreateDataObject({
        id: "dsInteractiveChartsParameters",
        viewName: "sviw_System_ProceduresParameters",
        maxRecords: 50,
        whereClause: "",
        selectFirstRowOnLoad: true,
        loadRecents: false,
        distinctRows: false,
        fields: [
            { name: "ProcName", type: "string" },
            { name: "ParamName", type: "number" },
            { name: "TypeName", type: "number" },
            { name: "max_length", type: "number" }
        ],
        masterDataObject_ID: "dsInteractiveCharts",
        masterDetailDefinition: [
            {
                detailField: "ProcName",
                masterField: "StoredProcedure",
                operator: "equals"
            }
        ],
    });
    

    const createUrl = function(){
        const url = {
            setForElement(){
                var itemArray:Array<String> = [];
                elementsFilterInputs.value.forEach(function(i:any){
                    if (String(i.Value) !== "" && !!i.Value) {
                        if(i.Type == 'date'){
                            itemArray.push(i.FieldName + "=" + utils.formatDate(i.Value, "Short Date"));
                        }else{
                            itemArray.push(i.FieldName + "=" + i.Value);
                        }
                    }
                });
                statusElementUrl.value = "/"+dsReportElementsUrlBuilder.current.ArticleID + (itemArray ? "?":"") + itemArray.join('&');
            },
            setForChart(){
                let chartUrl = document.getElementById("chart-iframe")!.contentWindow.document.querySelector("#preview-chart-img").src;
                statusElementUrl.value = chartUrl.substring(chartUrl.indexOf("api")-1);
            },
            setForReport(){
                var itemArray:Array<String> = [];
                reportsFilterInputs.value.forEach(function(i:any){
                    if (String(i.Value) !== "" && !!i.Value) {
                        if(i.Type == 'DatePicker'){
                            itemArray.push(i.Text + (i.HasApostrophe ? "'" : "") + utils.formatDate(i.Value, "Short Date") + (i.HasApostrophe ? "'" : ""));
                        }else if(i.Type == null) {
                            itemArray.push(i.Value);
                        }else{
                            itemArray.push(i.Text + (i.HasApostrophe ? "'" : "") + i.Value + (i.HasApostrophe ? "'" : ""));
                        }
                    }
                });
                statusElementUrl.value = "/file/view/reporting/" + dsMyReportsUrlBuilder.current.ReportID + "/" + dsMyReportsUrlBuilder.current.ReportID + ".png?Filter=" + itemArray.join(' AND ');
            },
            setForInteractiveCharts(){
                let urlParams:Array<String> = [];
                urlParams.push("ID="+dsInteractiveCharts.current.ID);
                Object.entries(interactiveChartParams.value).forEach(([key, value])=>{
                    urlParams.push(key + "=" + value);
                });
                statusElementUrl.value = "/chart?" + urlParams.join("&");
            }
        }
        return url;
    }

    const urlFunctions = createUrl();

    function onModalShown() {
        let activeTab = "elements";
        const url = props.dataObject.current.Url;

        if (url) {
            const setCurrentIndex = (builder, articleId, key) => {
                builder.load().then(() => {
                    const index = builder.data.find(article => article[key] === articleId);
                    if (index) {
                        builder.setCurrentIndex(index.index);
                        console.log(`CurrentIndex for ${activeTab}:`, builder.currentIndex);
                    } else {
                        console.log(`No matching article found for ${key}:`, articleId);
                    }
                });
            };

            if (url.includes('/chart-preview') || url.includes('/websitecms')) {
                activeTab = "charts";
                const articleId = extractArticleIdFromChartUrl(url);
                setCurrentIndex(dsChartsUrlBuilder, articleId, 'ChartLayout');

            } else if (url.includes('/file/view/reporting')) {
                activeTab = "reports";
                const articleId = extractArticleIdFromReporting(url);
                setCurrentIndex(dsMyReportsUrlBuilder, articleId, 'ReportID');
            } else if (url.includes('/chart?')) {
                activeTab = "interactiveCharts";
                // const articleId = extractArticleIdFromReporting(url);
                // setCurrentIndex(dsMyReportsUrlBuilder, articleId, 'ReportID');
            } else {
                activeTab = "elements";
                const articleId = extractArticleIdFromElements(url);
                setCurrentIndex(dsReportElementsUrlBuilder, articleId, 'ArticleID');
            }

            elementsTabsRef.value!.setActiveTab(activeTab);

        } else {
            elementsTabsRef.value!.setActiveTab(
                props.showElements ? "elements" :
                props.showCharts ? "charts" :
                props.showReports ? "reports" :
                props.showInteractiveCharts ? "interactiveCharts" : "elements"
            );
        }
    }

    function extractArticleIdFromChartUrl(url) {
        if (url.includes('/chart-preview')) {
            const params = new URLSearchParams(url.split('?')[1]);
            return params.get('Chart');
        } else if (url.includes('/websitecms')) {
            return url.split('/').pop();
        }
        return null;
    }

    function extractArticleIdFromReporting(url) {
        const parts = url.split('/');
        return parts[parts.length - 1].split('.')[0]; 
    }

    function extractArticleIdFromElements(url) {
        return url.split('?')[0].split('/').pop(); 
    }

    function clearValues() {
        dsChartsUrlBuilder.setCurrentIndex(0);
        dsMyReportsUrlBuilder.setCurrentIndex(0);
        dsReportElementsUrlBuilder.setCurrentIndex(0);
    }

    function getElementsInputs(filter, config){
        let fields = [];
        let objs = [];

        if(config){
            const parsedConfig = JSON.parse(config);
            parsedConfig.forEach(item => {
                const inputEditorConfig = item.InputEditorConfig ? JSON.parse(item.InputEditorConfig) : "";
                let objectToPush = {
                    ID: item.FieldName,
                    FieldName: item.FieldName,
                    Type: item.FieldType,
                    Default: item.DefaultValue,
                    Description: item.Description,
                    InputEditor: item.InputEditor,
                    InputEditorConfig: inputEditorConfig,
                    Datasource: item.Datasource,
                    DsFields: item.Fields,
                    DisplayField: item.DisplayField,
                    AdditionalData: {
                        Value: '',
                        ViewName: inputEditorConfig?.ViewName,
                        Fields: [],
                        Multiselect: inputEditorConfig?.Multiselect,
                        DisplayField: inputEditorConfig?.DisplayField
                    }
                };
                inputEditorConfig?.Columns?.split(",").forEach((i)=>{
                    let t = i.split(":");
                    objectToPush.AdditionalData.Fields.push({ name: t[0], type: t[2], width: t[1] });
                });
                objs.push(objectToPush);

            });
        }else if (filter) {
            fields = filter.slice(1).split("&");
            fields.forEach(function(i:String) {
                let obj = {
                    ID: i.substring(0, i.indexOf("=")),
                    FieldName: i.substring(0, i.indexOf("=")),
                    Type: i.includes(":") ? i.substring(i.indexOf("=") + 1, i.indexOf(":")) : i.substring(i.indexOf("=") + 1),
                    Description: i.includes("|") ? i.substring(i.indexOf("|") + 1, i.includes("+") ? i.indexOf("+") : i.length) : "",
                    DataSource: i.includes("+") ? i.substring(i.indexOf("+") + 1, i.includes(";") ? i.indexOf(";") : i.length) : "",
                    DsFields: i.includes(";") ? i.substring(i.indexOf(";") + 1, i.includes("-") ? i.indexOf("-") : i.length) : "",
                    DisplayField: i.includes("-") ? i.substring(i.indexOf("-") + 1) : "",
                    Value: i.includes(":") ? i.substring(i.indexOf(":") + 1, i.includes("|") ? i.indexOf("|") : i.length) : "",
                    AdditionalData: {
                        Value: '',
                        ViewName: '',
                        Fields: [{ name: "", type: "" }],
                        Multiselect: false,
                        DisplayField: ""
                    }
                };
                if(props.dataObject.current.Url && !obj.Value){
                    var url = new URL("https://www.omega365.com" + props.dataObject.current.Url);
                    obj.Value = url.searchParams.get(obj.FieldName);
                }

                objs.push(obj);
            });
        }
        return objs;
    }

    function getReportsInputs(row){
        let tempList:Array<String>;
        reportsFilterInputs.value = [];
        if(reportSelectedFilter.value === "Default"){
            tempList = dsMyReportsUrlBuilder.current.FilterTemplate?.split(" AND ");
        }else{
            tempList = row.FilterTemplate.split(" AND ");
        }

        tempList?.forEach((i:String)=>{
            let regex = new RegExp('{{(.*?)}}');
            if(i.match(regex)) {
                let parseObj = i.split("{{");
                parseObj[1] = parseObj[1].replace("}}", "").replace("'", "");
                let lookupItem = dsSystemInputEditors.data.find(obj => obj.Name == parseObj[1]);
                let config = !lookupItem?.Config ? {} : JSON.parse(lookupItem.Config);
                reportsFilterInputs.value.push(
                    {
                        ID: parseObj[1]+reportsFilterInputs.value.length, 
                        Text: parseObj[0].slice(0, parseObj[0].length - 1).replaceAll(" ", ""), 
                        Type: parseObj[1], 
                        Value: "", 
                        HasApostrophe: parseObj[0].includes("'"),
                        ViewName: config?.ViewName,
                        Fields: [],
                        Multiselect: !!config?.Multiselect,
                        DisplayField: config?.DisplayMember
                    }
                );
                config?.Columns?.split(",").forEach((i)=>{
                    let t = i.split(":");
                    reportsFilterInputs.value[reportsFilterInputs.value.length-1].Fields.push({ name: t[0], type: t[2] });
                });
            } else {
                reportsFilterInputs.value.push({
                    ID: null, 
                    Text: i, 
                    Type: null, 
                    Value: `${i}`,
                    HasApostrophe: i.includes("'"),
                    ViewName: null,
                    Fields: [],
                    Multiselect: null,
                    DisplayField: null
                });
            }
        });
    }

    function setUrl(){
        if(elementsTabsRef.value!.getActiveTab() == "charts"){
            urlFunctions.setForChart();
        }
        if(elementsTabsRef.value!.getActiveTab() == "interactiveCharts"){
            urlFunctions.setForInteractiveCharts();
        }

        props.dataObject.current.Url = statusElementUrl.value;
        props.dataObject.save();
        configUrlModal.value!.hide();
    }

    function lookupCallback(row, item){
        let i = elementsFilterInputs.value.find(obj => obj.ID == item.ID);
        i.Value = row[item.DisplayField];
    }

    function reportLookupCallback(row, item){
        let i = reportsFilterInputs.value.find(obj => obj.ID == item.ID);
        i.Value = row[item.DisplayField];
    }

    function onReportLoaded(){
        isReportLoading.value = false;
    }

    function isCustomReportLookup(type){
        if(dsSystemInputEditors.data.find(obj => obj.Name == type)){
            return true;
        }else{
            return false;
        }
    }

    watch(reportSelectedFilter, () => {
        getReportsInputs(reportSelectedFilter.value);
    });

    watch(elementsFilterInputs, () => {
            urlFunctions.setForElement();
        },
        { deep: true }
    );

    watch(reportsFilterInputs, () => {
            urlFunctions.setForReport();
        },
        { deep: true }
    );

    onMounted(()=>{
        useDataObjectEventListener(dsReportElementsUrlBuilder, 'CurrentIndexChanged', () => {
            elementsFilterInputs.value = getElementsInputs(dsReportElementsUrlBuilder.current.QueryString, dsReportElementsUrlBuilder.current.ElementConfigJSON);
        });

        useDataObjectEventListener(dsMyReportsUrlBuilder, 'CurrentIndexChanged', () => {
            loadReport.value = false;
            isReportLoading.value = true;
            reportSelectedFilter.value = "Default";
        });

        useDataObjectEventListener(dsMyReportsFilterTemplates, 'CurrentIndexChanged', () => {
            getReportsInputs();
        });

        useDataObjectEventListener(dsMyReportsFilterTemplates, 'DataLoaded', () => {
            getReportsInputs();
        });

        useDataObjectEventListener(dsInteractiveCharts, 'CurrentIndexChanged', () => {
            console.log("int chart", interactiveChartParams.value)
            interactiveChartParams.value = {};
        });

        dsSystemInputEditors.load();
    });
</script>